// extracted by mini-css-extract-plugin
export var footer = "id";
export var footerBottom = "pd";
export var footerBottom__copyWrapper = "td";
export var footerBottom__footerLink = "sd";
export var footerBottom__footerSocial = "vd";
export var footerBottom__left = "qd";
export var footerBottom__links = "rd";
export var footerBottom__textDeveloper = "ud";
export var footerTop = "kd";
export var footerTop__appButton = "nd";
export var footerTop__logo = "od";
export var footerTop__menu = "md";
export var footerWrapper = "jd";
export var withButton = "ld";